import { Registration, ScanningConfiguration } from "@marketpartner/backend-api"

export type MatchedQrCode = {
    tokenOrFieldValue: string
    registration?: Registration
}

export const parseAndMatchRegistrationQrCode = (
    qrData: string,
    { customTokenField }: ScanningConfiguration,
    registrations: Registration[],
): MatchedQrCode => {
    if (customTokenField) {
        const fieldValue = qrData.trim()

        if (!fieldValue) {
            throw new Error("Empty QR code")
        }

        return {
            tokenOrFieldValue: fieldValue,
            registration: registrations.find(registration => registration.fields[customTokenField] === fieldValue)
        }
    } else {
        const match = qrData.match(/^\{([a-z0-9]{16})\}$/)

        if (!match) {
            throw new Error(`Unexpected QR code format: ${qrData}`)
        }

        return {
            tokenOrFieldValue: match[1],
            registration: registrations.find(registration => registration.accessToken === match[1])
        }
    }
}