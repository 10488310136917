import { v4 as uuidV4 } from 'uuid'

export enum BackgroundUpdateState {
    Pending = "Pending",
    Updating = "Updating",
    RetryPending = "RetryPending",
    Failed = "Failed",
}

export type BackgroundUpdateItem<T> = {
    id: string
    item: T
    state: BackgroundUpdateState
    errorMessage?: string
    updateStarted?: string
}

export type BackgroundItemsById<T> = Record<string, BackgroundUpdateItem<T>>

/**
 * Provides local storage for items awaiting background processing.
 * Note that useLocalStorage is not used because it does not handle
 * updates from asynchronous tasks after unmounting or key change.
 * 
 * There should be no need to use this directly (see useBackgroundUpdate instead).
 */
export class BackgroundUpdateStorage<T> {
    onChange?: (items: BackgroundUpdateItem<T>[]) => unknown

    constructor(
        private key: string
    ) { }

    getItemsById = (): BackgroundItemsById<T> => {
        const json = localStorage.getItem(this.key)
        if (json) {
            return JSON.parse(json)
        }
        return {}
    }

    getItems = (): BackgroundUpdateItem<T>[] => {
        return Object.values(this.getItemsById())
    }

    addItem = (item: T) => {
        this.modifyItems(itemsById => {
            const id = uuidV4()
            itemsById[id] = {
                id,
                item,
                state: BackgroundUpdateState.Pending,
            }
        })
    }

    removeItem = (id: string) => {
        this.modifyItems(itemsById => {
            delete itemsById[id]
        })
    }

    updateState = (id: string, update: Partial<Omit<BackgroundUpdateItem<any>, "id" | "item">>) => {
        this.modifyItems(itemsById => {
            itemsById[id] = {
                ...itemsById[id],
                ...update,
            }
        })
    }

    private modifyItems = (modification: (items: BackgroundItemsById<T>) => void) => {
        const items = this.getItemsById()
        modification(items)
        this.onChange?.(Object.values(items))
        localStorage.setItem(this.key, JSON.stringify(items))
    }
}