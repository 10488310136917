import { BackendOptions, buildBackend } from '@marketpartner/backend-api'
import { buildBackendHooks } from '@marketpartner/mp-common-react'
import { findAccessToken } from 'src/login/useTokenLogin'

const options: BackendOptions = {
    credentialsProvider: () => {
        const accessToken = findAccessToken()
        return accessToken ? {
            type: "AccessToken",
            accessToken
        } : undefined
    }
}

export const legacyBackend = buildBackend(options)
export const backend = buildBackendHooks(options)