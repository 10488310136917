import { CurrentAuthorization, Permission } from "@marketpartner/backend-api"
import { getErrorString } from "@marketpartner/mp-common"
import { useLocalStorage, useUrlState } from "@marketpartner/mp-common-react"
import { useEffect } from "react"
import { backend } from "src/common/backend"


const accessTokenKey = "scanner-access-token"

export function findAccessToken(): string | undefined {
    const tokenJson = localStorage.getItem(accessTokenKey)
    return tokenJson ? JSON.parse(tokenJson) : undefined
}

export type TokenLoginState = {
    isLoggingIn: boolean
    isLoggedIn: boolean
    loginError?: string
    authorization?: CurrentAuthorization
    login: (token: string, onSuccess?: () => unknown) => unknown
    logout: () => unknown
}

export const useTokenLogin = (): TokenLoginState => {
    const [urlToken, setUrlToken] = useUrlState("token")
    const [token, setToken] = useLocalStorage<string>(accessTokenKey, "")

    const authQuery = backend.authorization.useGetCurrentAuthorization([], {
        enabled: false
    })

    useEffect(() => {
        if (urlToken) {
            login(urlToken)
            setUrlToken("")
            authQuery.refetch()
        } else if (token) {
            authQuery.refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlToken, token])

    const login = (token: string) => {
        token = token.trim()
        setToken(token)
        authQuery.refetch()
    }
    const logout = () => login("")

    const auth = validateAuth(authQuery.data)
    const loginError = authQuery.error ?? auth.loginError
    return {
        authorization: auth.authorization,
        loginError: loginError && getErrorString(loginError),
        isLoggingIn: authQuery.isFetching,
        isLoggedIn: Boolean(auth.authorization),
        login,
        logout,
    }
}

const validateAuth = (authorization?: CurrentAuthorization): Pick<TokenLoginState, "authorization" | "loginError"> => {
    if (!authorization) {
        return {}
    }
    const permissions = Object.keys(authorization.permissions)
    if (!permissions.length) {
        return {} // Anonymous
    }
    if (!permissions.includes(Permission.MarkAttendance)) {
        return { loginError: "You do not have permission to access the scanner." }
    }
    if (!authorization.singleClientId || !authorization.singleEventId) {
        return { loginError: "User is not associated with a single event. Scanner app does not currently work with multiple events." }
    }
    return { authorization }
}
